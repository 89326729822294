import React, { useState } from "react";
import moment from "moment";
import logo from "./ns4.png";
import instaLogo from "./insta_logo.png";
import { SignUp } from "./SignUp";
import { Login } from "./Login";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "./firebase-config";
//import { motion } from "framer-motion";

//HOC - always returns result when the same input occurs
export const Header = React.memo(() => {

  const [date] = useState(moment().format('dddd Do MMMM YYYY'));
  const [isShown, setIsShown] = useState(false);
  const [isShown2, setIsShown2] = useState(false);
  const [createButtonText, setCreateButtonText] = useState("Create account");
  const [buttonText2, setButtonText2] = useState("Log in");
  const [user, setUser] = useState({});

  onAuthStateChanged(auth, (currentUser) => {
    console.log('currentUser', currentUser);
    if (currentUser === null) {
      return
    } else {
      setUser(currentUser);
      }
  });

  const handleSignUp = event => {
    setIsShown(current => !current);
    setCreateButtonText((buttonState) => (buttonState === "Close" ? "Create account" : "Close"));
  };

  const handleLogin = event => {
    setIsShown2(current2 => !current2);
    setButtonText2((buttonState2) => (buttonState2 === "Close" ? "Log in" : "Close"));
  };

  const logout = async () => {
    await signOut(auth);
    window.location.reload();
    // return (
    //   <motion.div
    //     className="modal"
    //     initial={{ opacity: 0, y: 50 }}
    //     animate={{ opacity: 1, y: 0 }}
    //     exit={{ opacity: 0, y: 50 }}
    //   >
    //     <p className="modal-intro">You are logged out!</p>

    //   </motion.div>
    // );
  };

    return(
      <div>
      <header>
        <p>
        <span className="date">{date}
        <a href="https://instagram.com/nooching_health" target="_blank" rel="noopener noreferrer">
          <img
            src={logo}
            alt="nooching_support"
            className="avatar"
          />
        </a>
        
        </span>
        </p>
        <h1>Today</h1>
        <h4 className="date">
          { user.email && 
            <p className="LoggedInText">
              <span>Logged in:</span> { user.email }</p>
          }
        </h4>


        { !user.email &&
        <span>
        <button
        className="create-account-button"
        onClick={handleSignUp}
        >
          {createButtonText}
        </button>
        </span>
        }
        <span
          className="buttonSpacing">
        </span>
        { !user.email &&
        <span>
        <button
        className="add-article-button"
        onClick={handleLogin}
        >
          {buttonText2}
        </button>
        </span>
        }

        { user.email &&

        <button
          className="add-article-button"
          onClick={logout}> Sign Out </button>

        }
        <p></p>

        <a href="https://instagram.com/nooching_health" target="_blank" rel="noopener noreferrer">
          <img
            src={instaLogo}
            className="instaLogo"
            alt="insta_logo"
          />
        </a>
        <h4 className="supportMessage">
          <p>nooching.health@gmail.com </p>
        </h4>
        <hr className="hrule" />
        <p></p>
        <p></p>
    </header>
    <p>
      { isShown && (
    <div>
      
    </div>
  )}
      {isShown && 
    <p>
      <SignUp />
    <p></p>
    <p></p>
    <hr className="hrule" />
    </p>
  }
  </p>
  <p>
      {isShown2 && (
    <div>
      
    </div>
  )}
      {isShown2 && 
    <p>
      <Login />
    <p></p>
    <p></p>
    <hr className="hrule" />
    </p>
  }
  </p>
  </div>
);
});