import React, { useState} from "react";
import { Card } from "./Card";
import { BrowserRouter as Router, Route } from "react-router-dom";
import cardData from "./data";
//import { motion, useInvertedScale } from "framer-motion";

//List function that ties the card to the routes
//Pulls in the actual card data - should pull in content here

const List = ({ match, history }) => {
  const [query, setQuery] = useState("");

  return (
    <div>
      <h1 className="open-articles-text">Create account to read these articles!</h1>
      <input
        className="search-filter"
        placeholder="Search for articles by name or category"
        onChange={event => setQuery(event.target.value)}
      />

      <ul className="card-list">
        {
          cardData.filter(data => {
            if (query === '') {
              return data;
            } else if (
              data.title.toLowerCase().includes(query.toLowerCase())
              ||
              data.category.toLowerCase().includes(query.toLowerCase())
              ) {
              return data;
            }
          }).map((data) => (
            
            <Card
              key={data.id}
              isSelected={match.params.id === data.id} // eg. c === c - true
              history={history}
              {...data}
              />
          ))
        }
      </ul>
    </div>
  )
};

//Pass List as props to the router in CardList component
//CardList gets rendered by the main App component
export const CardList = () => (
  <Router>
    <Route path={["/:id", "/"]} component={List} />
  </Router>
);