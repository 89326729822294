import React from "react";
import { motion, useInvertedScale } from "framer-motion";

export const Text = ({ text, b1, b2, b3, b4, b5, b6, b7, b8, b9, b10 }) => {
  const inverted = useInvertedScale();

  return (
    <motion.div
      className="content-container"
      style={{ ...inverted, originX: 0, originY: 0 }}
    >
      <p className="text">{text}</p>
      <p className="text">{b1}</p>
      <p className="text">{b2}</p>
      <p className="text">{b3}</p>
      <p className="text">{b4}</p>
      <p className="text">{b5}</p>
      <p className="text">{b6}</p>
      <p className="text">{b7}</p>
      <p className="text">{b8}</p>
      <p className="text">{b9}</p>
      <p className="text">{b10}</p>
    </motion.div>
  );
};
