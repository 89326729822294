import React from "react";

export const Footer = React.memo(() => {
    return(
      <div>
        <hr className="hrule" />
          <div className="copyright-text">
            © Copyright 2023. All rights reserved.
          </div>
      </div>
        

);
});