const data = [
{
id: "benefits_of_fasting_for_healing",
category: "food",
title: "Benefits of fasting for healing",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "Fasting is recommended to everyone for both mental and spiritual reasons, and has been used for a number of years to enhance the repair and growth of the body. Fasting is when one decides to go without food/water for a set number of hours, so that the body has a chance to stabilise and have digestive rest. If you have any addictions to food, fasting can help to break these addictions and can mean that you will crave more nutrient dense foods of a better quality.",
b2: "From the ancient Egyptians, to the Sumerians and in most religions throughout our society today, fasting is advocated for different reasons. Most animals know when to fast, if they become sick for example, it's likely you'll find that they won't consume anything. First they will try to purge their systems of the sickness. Unfortunately we as humans don't have this intrinsically built within us.",
b3: "There are a few different types of fasting you can adopt: a. intermittent fasting, b. alternate day fasting, c. liquid fasting, d. mono-fasting - only eating one type of food, e. water fasting and f. dry fasting - not recommended - where you don't eat or drink anything for a period (speak to a doctor before doing this as most people are not healthy enough to undertake this). This process has many benefits, some are as follows:",
b4: "1. Reduces inflammation all over the body.. period.",
b5: "2. Heart health - when fasting is carried out over a long period of time and incorporated as a lifestyle choice, your blood pressure and cholesterol levels are likely to be reduced.",
b6: "3. Insulin resistance - anyone who suffers with type 2 diabetes can benefit from the effects of fasting. Insulin is a fat storage hormone and fasting will reduce your sugar intake and decrease fat tissues.",
b7: "4. Brain function - it can boost the function of your brain and help to regenerate brain cells. It can also help with common brain diseases.",
b8: "5. Growth hormone - makes you stronger and keeps you younger for longer.",
b9: "6. Autophagy - the body will begin to self-heal. Each cell in the body is cleaned and waste is removed. Through this your energy levels will begin to increase.",
b10: "Bottom line is that fasting is not a way to starve yourself. It is a controlled and disciplined way of refraining from the consumption of food. People can make the choice to do this with the correct information and research.",
source: "https://www.youtube.com/watch?v=4myqoMHVf6Q",
date_created: "2023-07-25"
},        
{
id: "foods_to_avoid_to_stay_healthy",
category: "food",
title: "Foods to avoid to stay healthy",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "All of the following foods cause inflammation and this is the foundation of most diseases in the body. They cause irritation of body tissues which can then lead to bigger problems down the line for our organs. All of these foods will make it difficult to have bowel movements and are toxic, not containing the required amount of fibre and containing unwanted chemicals.",
b2: "1. Dairy - Can be very addictive and is very toxic to our systems. Many of the diary cows we use are injected with hormones that can make it into or bodies.",
b3: "2. Sugar - Ruins the good bacteria inside of our gut, which compromises our immune system. In turn you become more prone to infection. Nevertheless, it's good to consume the sugars that come alongside the fibre inside our fruits.",
b4: "3. Seed oils - seed oils like Canola oil are refined and can be very inflammatory to the blood vessels in the heart. Be sure to check the labels on your foods to avoid these kinds of oils where you can. The dangerous part comes where you add high temperatures to these oils, they become de-natured and are a health risk.",
b5: "4 Processed foods - Average diets can contain around 60 - 70% of processed foods which are inflammatory. It's best to swap these out for whole foods that taste much better.",
b6: "5. Refined carbohydrates and white flour - Most of these foods have no nutrient content and the refining process has removed the goodness. This can cause huge issues for the gut and overall health.",
b7: "6. Soy protein isolates - Many artificial meats are made from Soy. The original healthy Soy products were usually fermented making them healthier. Most Soy products nowadays go through chemical processing which make them toxic to the body.",
b8: "7. Meats - Many animals are given chemicals to make them grow for sale purposes. Unfortunately many of these animals can also get sick during rearing and the antibiotics can end up being ingested by the end consumers.",
source: "https://www.youtube.com/watch?v=EalfUMftryQ",
date_created: "2023-07-24"
},        
{
id: "helping_your_body_defend_against_potential_infections",
category: "health",
title: "Helping your body defend against potential infections",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "When there is a flu outbreak or virus of some sort, our society tends to focus on ways and measures to prevent and contain the spread (which is great). Let's look at some ways that we can equip the body to resist and defend against some of these spreads - if we do unfortunately come into contact with them. Through increasing immunity we can try to decrease the chances of us getting seriously ill.",
b2: "1. Avoiding exposure - Through improving hygiene and sanitation we can decrease our chances of ever coming into contact with anything harmful to begin with. Doing the obvious things.. washing hands and keeping surfaces clean.",
b3: "2. Nutrition - By decreasing sugar intake we can improve the function of the immune system and bolster our defenses. Sugar can actually feed the pathogens and bacteria in the body that we have contracted through the environment. Start consuming fresh whole foods, all of the immune cells in the body need high quality minerals and vitamins in order to be produced and in turn protect us.",
b4: "3. By pushing yourself during exercises, through routines like high interval training and lifting heavy weights, you can force your body to improve. This can increase your white blood cell count and activate your DNA so it performs better in the long run.",
b5: "4. Using exposure to hot then cold temperatures alternatively in the shower. Your body will be forced to deal with higher amounts of stress and increase your chance of preventing pathogens from affecting the body.",
b6: "5. Sleep - getting enough quality sleep could mean that you are giving your body enough time to fully repair if it has been compromised. If fully rested your body should be in better shape for defense the following day.",
b7: "6. Meditation, breathing and relaxation training can all be utilised as a direct response to being stressed out. By focusing on something else other than your direct stress, you can put your body in a better state for defense.",
b8: "7. Balance - If we completely disinfect and do everything to avoid harmful environmental pathogens, we put the body at risk by not letting it create the defenses it actually needs. Not everything in this list needs to be carried out to it's extreme. High intensity interval training for example, doing this a few times a week is more than enough, don't over-do it.",
source: "https://www.youtube.com/watch?v=lb07hPLzojY",
date_created: "2023-07-23"
},
{
id: "ways_to_boost_testosterone",
category: "health",
title: "Ways to boost Testosterone",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "Let's look at some ways that you can naturally increase the Testosterone levels in the body.",
b2: "1. Cholesterol - It's important to increase your Cholesterol levels by not going on a low fat diet. Most people would think that reducing this would be beneficial. Cholesterol is used by the body to build Testosterone and is therefore required for its increase.",
b3: "2. Protein - You do not need excessive amounts of Protein to boost Testosterone, but a moderate amount is needed in relation to your body size per meal. Eating too much can mean that your body turns the excess into glucose and only uses the amino acids it's needs.",
b4: "3. Carbohydrates - Keep your carbs low so that you don't trigger spikes in your blood sugar. This can help to increase your Testosterone and growth hormone levels.",
b5: "4. Exercises - Ensure that you are doing exercises that are causing sweating and have an element of high intensity to trigger Testosterone. Be sure not to over-do things though, as this can have the opposite effective through stress. Start by doing low intensity exercises, like long walks and light jogs, and then increase this to more vigorous forms of training.",
b6: "5. Sleep - Increase your sleep to provide the environment of low Cortisol in the body. Testosterone will decrease if Cortisol increases.",
b7: "6. Estrogen - Avoid Soy products and foods in heavy plastic packaging. Estrogen can be high in fake meats, some gym powders and diet foods. Try to buy organic foods. The pesticides and insecticides they spray on non-organic foods behave like Estrogen when ingested into the body.",
b8: "7. Supplements - Zinc and Ginseng supplements can also help with Testosterone.",
source: "https://www.youtube.com/watch?v=Wto7ajhH970",
date_created: "2023-07-22"
},
{
id: "signs_your_body_may_be_toxic",
category: "health",
title: "Signs your body may be toxic",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "Mineral deficiency and the toxicity of our foods are two of the main reasons for the increase in the bad health conditions many of us face today. Even though we are consuming food in large amounts, they are usually low in nutrients, and contain chemicals used for sterilisation and preservation purposes.",
b2: "In many cases for example, people are carrying heavy metal toxins and won't know that they need to consume certain herbs to rid the body of them. The following points are some potential signs of toxicity in the body:",
b3: "1. Skin issues - The skin is the largest elimination organ of the body. So through the skin, waste should be leaving. If for example you are getting acne in certain areas on your face, it could suggest that you are being exposed to too many hormonal toxins through the diet.",
b4: "2. Sleep issues - Certain foods can disrupt your hormonal balance, and you can experience conditions like waking up at night in sweats.",
b5: "3. Gut issues - If your bowel movements are being delayed, this could build up and remain in the body and cause bloating. The food that you eat should always be eliminated and the number of times you eat should roughly correspond to the number of bowel movements you have.",
b6: "4. Mood swings - toxins can get into the brain through the blood and cause things like anxiety.",
b7: "5. Chronic fatigue - If you need to sleep at random times during the day (for example when you get to work), this could be a sign that you have heavy metals in the body.",
b8: "6. Concentration - If your diet contains processed chemical foods, the toxins can get into the blood and cause irritability.",
b9: "7. Weight gain - Your body has to store bad food toxins in fat tissues. As you consume these foods your body has to make the decision to store them in places that are not the blood, or the bones for example.",
b10: "8 Migraines and headaches - Toxins can get into the blood and cause inflammation in the brain.",
source: "https://www.youtube.com/watch?v=q2msQY5gJKM",
date_created: "2023-07-21"
},
{
id: "the_importance_of_drinking_water",
category: "food",
title: "The importance of drinking water!",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "The h2O water molecule is one of the most important molecules known. The human body consists of about 60% of these molecules and make up percentages of our tissues and bones.",
b2: "Our lives are possible due to water being soluble - the fact that it has a certain degree of separation between the 2 hydrogen atoms, meaning anything can be dissolved into it. As we consume and digest foods, waste is created and carried away through the water systems in the body.",
b3: " Water is used in the body in many ways, some of these include, 1. Temperature regulation, 2. Lubrication of joints, 3. Helping the central nervous system, 4. Keeping tissues moist, 5. Supporting your GI tract and 6. Preventing Dehydration. Literally every single chemical reaction requires water!",
b4: "We always need to replenish the water we have simply because we keep loosing it throughout the day. This can be through, 1. At rest when we are doing nothing and at a stand still, 2. Through urination, 3. Sweating, 4. Breathing and 5. Bowel movements.",
b5: " The body can sense when the concentration of cells in the body has increased and it is able to adjust for this condition (meaning there is not enough water present). The Kidneys can then decide to retain or release water to control this.",
b6: "As your body looses water the following can happen, 1. You start to get thirsty, 2. Your energy begins to decrease, 3. Your mood begins to decrease, 4. Your skin elasticity decreases, 5. You won't be able to think as clearly, 6. You begin to feel faint, and then, 7. Organ failure.",
b7: " This can happen simply because there is not enough water available for the chemical reactions in the body to take place in. Basically, there is a limit to how much our Kidneys can concentrate the urine with the waste products from these chemical reactions.",
b8: "So... there is not a particular amount of water that you need to drink, but you need to drink enough. The body can extract water from anything we consume, so anything from Coffee, to Tea or Herbal teas all count. Most vegetables have high water content so these are great for you.",
b9: "Drinking around 1 - 3 Litres per day could be a sensible amount, but this should be adjusted up or down depending on your activity level, the temperature in your environment and your body size.",
b10: "You can also judge how much water you need through your frequency of using the bathroom, and the concentration of your urine. High frequency and low concentration and you are probably consuming too much. Low frequency and high concentration and you are probably not consuming enough. It's important to learn your own body and decide for yourself!",
source: "https://www.youtube.com/watch?v=QP8-jZj0wT4",
date_created: "2023-07-19"
},        
{
id: "foods_with_almost_zero_calories",
category: "food",
title: "Foods with almost zero calories",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "Focus on what foods do contain rather than what they do not contain when it comes to zero calories. If they don't contain anything beneficial for our bodies, then there's probably no point eating it. Even though it sounds like a good idea for foods to have zero calories, we cannot survive without calories and energy.",
b2: "For foods to be worth it they need to contain vitamins, minerals, fiber and other nutrients for the body. Counting calories may not be the best idea to cut back and loose weight. You don't want to end up in situation after a while where you feel deprived and lack energy. You need to eat to feel satisfied, so you don't keep snacking.",
b3: "1. Leafy greens are a great example of an almost zero calorie food type. Foods like Lettuce, Arugula and Kale contain sugar, water and fiber. Due to the water content, this sugar is released very slowly, meaning you don't experience high spikes in your blood sugar.",
b4: "2. Fruits - Apricots, Watermelon, Apples - these fruits can contain quite high amounts of sugar and should be consumed with caution and in moderation. Even though they are healthy, you are still consuming sugar.",
b5: "3. Celery, Cabbage, Asparagus, Cauliflower, Broccoli. These non-starchy vegetables are great as a zero calorie food, and you can consume as much as you need.",
b6: "4. Raspberries and Blackberries - similar to the non-starchy veggies and you can eat a moderate amount without raising blood sugar levels too high.",
b7: "5. Strawberries and Blueberries - are great but also should be eaten in moderation and shouldn't be treated the same as Raspberries and Blackberries.",
source: "https://www.youtube.com/watch?v=MT-hGMnmg6U",
date_created: "2023-07-18"
},
{
id: "healthier_teeth_and_gums",
category: "health",
title: "Healthier teeth and gums",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "Diseases we contract usually start in the mouth, as this is where our body first comes into contact with the foods we choose to consume. Gum diseases can ultimately lead to decaying teeth and can be a precursor for other health conditions further down the line.",
b2: "Gum disease is an inflammation of the gum and tissues that hold the teeth together. If we eat unhealthy foods this can leave residue on the teeth. If brushing is not done properly, the plaque can start to eat away at your enamel and begin to cause issues.",
b3: "Some signs that you could be starting to develop a problem here are, 1. Bad breath, 2. More of the tooth being exposed in correlation to the gum, 3. Bleeding gums, 4. Bad tastes that don't go away as you continue to eat. 5. Red and swollen gums. 6. Pain while chewing.",
b4: " Everything in the body works together, so if you notice these symptoms in the mouth, take care to check your overall health indicators. Gum health is linked to all of your health, use them similarly to indicator lights on a car dashboard.",
b5: "The following points can help to improve your oral health:",
b6: "1. Changing your diet - oral health can be improved by reducing the starches, sugar and processed foods we eat.",
b7: "2. Smoking - This deprives your blood stream of oxygen and prevents your body from healing your gums.",
b8: "3. Cleaning your teeth - It would best to get a water pik, so you can fully clean in between your teeth and gums. Most of the issues begin when you don't floss or don't efficiently remove left over food particles, that get stuck around the teeth.",
b9: "4. Increase your vitamin C - ensure you are eating enough fruits and vegetables so you can boost your oral health immunity. Foods like Guava, sweet peppers, Lychee and Papaya have much more vitamin C than fruits like Oranges.",
b10: "5. Oil pulling - take Coconut oil and a few drops of Clove oil. Move the solution around your mouth for around 2 minutes and spit it out into a bin. This can help to remove parasites in the mouth.",
source: "https://www.youtube.com/watch?v=XcxxPvlSPO4",
date_created: "2023-07-17"
},        
{
id: "how_to_build_muscle_on_plant_based_diet",
category: "food",
title: "How to build muscle on a plant-based diet",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "As time goes by and we age it's easy to observe that we tend to start loosing muscle and gaining fat. In general those of us that have lean muscle mass tend to have healthier bodies. By choosing to opt for a plant-based diet we can avoid the saturated fats that come with eating meats, which can cause most of the health problems in society today, from high blood pressure to some forms of cancer.",
b2: "People claim that you can't get complete protein from only eating plants... two sources of complete plant proteins are Chia and Hemp seeds. Browse the following points to help your plant muscle building journey.",
b3: "1. Body composition - Proteins are a combination of amino acids that we need to build our muscles. Our bodies are capable of rebuilding parts of the body that have been damaged. So as long as we provide the body with the correct amino acids, the body can decide what type of protein to build to repair itself. Overall plants are much easier to break down and use in comparison to meats.",
b4: "2. Increase your healthy calorie intake through only plant-based sources. Add these calories through drinking smoothies containing green leafy vegetables, berries, nuts, nut milks and avocados etc.",
b5: "3. Eat high quality protein - Chickpeas, Quinoa, Spelt, Teff, Chia seeds, Mushrooms, Hemp seeds and wild rice.",
b6: "4. Hormonal imbalances - It's important to correct any imbalances you may have in order to support muscle growth. Things like high blood sugars can cause your body to store fat rather than muscle. Also, ensure that excessive estrogen isn't entering your body, these can be through plastics, birth control pills, processed foods and certain cosmetic and hygiene products. All of these can make you store fat.",
b7: "5. Exercising - the most obvious step to observe here is to actually workout out your muscles. They will be broken down and re-built in the shape that you desire. Begin with body weight exercises... push-ups, squats, pull-ups, so you can master your own body weight first and then move on to adding weight. This will mean your structure is healthier in the beginning and then you can move on and build as much muscle as you need.",
source: "https://www.youtube.com/watch?v=AbKQ5HSlU2M",
date_created: "2023-07-16"
},
{
id: "help_cleanse_your_colon!",
category: "health",
title: "Help cleanse your colon!",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "Our colon can take a battering over time and unfortunately the majority of us will experience cramping, bloating or constipation at some point or other. To help with this, one of the best remedies is to drink plenty of fluids. This aids digestion and moves food through the system to reduce build up and blockages. Try to consume around half of your total weight in water or fluids to get this balance right.",
b2: "If you have issues drinking enough fluids, try to consume fruits and vegetables that are mostly made up of water, examples are melons and cucumbers.",
b3: "1. Try adding a teaspoon of sea or himalayan salt to a full glass of warm water to get your bowels moving.",
b4: "2. Before going to bed you could try to implement a routine where you drink a cup of warm water to melt all of those toxins you've ingested throughout the day, helping your bowels.",
b5: "3. Increase your intake of fibrous vegetables to bulk up the material your body will be required to remove.",
b6: "4. Eating Apples, Lemons, Aloe vera and Chia seeds through things like smoothies can help the process of cleansing your colon.",
b7: "5. Apple cider vinegar, Sauerkraut, pickles, Miso soup and Kefir are all food types that encourage ease on the colon.",
b8: "6. Increase your consumption of teas for great food digestion... from Senna, to Dandelion, to Peppermint, to Chamomile, to all the green teas... they can be a great help. Even drinking Coffee can make the colon more active. Add in a cup of freshly grated Ginger tea here and there and things should be fine. These are all powerful remedies to keep things in motion.",
source: "https://www.youtube.com/watch?v=e85leegsV9U&t=189s",
date_created: "2023-07-15"
},       
{
id: "natural_ways_to_unclog_your_arteries",
category: "health",
title: "Natural ways to unclog your arteries",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "An increase in sugar levels in the body through the diet can contribute to high Cholesterol and inflammation. This in turn can affect the heart tremendously. Cholesterol has many jobs but one is to repair the arteries as they come into contact with this sugar intake. Over time this Cholesterol layer becomes thicker and restricts blood flow and can lead to heart attacks. You can attempt to prevent this by including the foods and processes below.",
b2: "1. Ensure that you are exercising - this will increase your blood flow and move fluid around your body, excreting more waste products. If you are seated for most of the day at work, check to see if you can switch to an adjustable standing desk.",
b3: "2. Pomegranate - a great anti-oxidant for the arteries.",
b4: "3. Nuts and seeds - good for preventing oxidation (hardening of the Cholesterol particles in the arteries).",
b5: "4. Ginger root - an inflammatory that can be consumed as a tea.",
b6: "5. Leafy greens - high in Magnesium and Potassium.",
b7: "6. Intermittent fasting - giving your arteries a chance to recover and repair.",
b8: "7. Nettle (Stinging Nettle) - reducing blood sugars for better artery function.",
source: "https://www.youtube.com/watch?v=WBeNmAJB2bs",
date_created: "2023-07-13"
},
{
id: "foods_to_help_your_liver_health",
category: "food",
title: "Foods to help your Liver health",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "There are many super-foods that we can consume to improve the health of our Liver. Please remember that even if you do eat these foods, it will have no effect on your Liver if you are consuming a majority of unhealthy foods alongside it.",
b2: "A lifestyle shift is the most important decision for your overall health, so that you aren't just responding to bad health using medication or going on a temporary diet that you may have to end. Unfortunately the majority of diets end after a specific period of time and people tend to regain the lost weight and can gain more weight.",
b3: "1. The first food group (in no particular order) are the green leafy vegetables - loaded with minerals that can help to detoxify the Liver and restore it, removing those horrible toxins.",
b4: "2. Blueberries - contain Phytonutrients that can help prevent the cells of the Liver from oxidative stress. Oxidative stress occurs as the Liver is involved in chemical reactions over time. This is probably one of the best snacks you can have alongside nuts.",
b5: "3. Grapes - seeded Grapes that are organic can help to prevent cellular damage, decrease inflammation and help with anti-oxidation similar to Blueberries",
b6: "4. Opuntia (aka prickly Pear) - part of the cacti family, this may be a bit more difficult to locate but also helps to remove toxins.",
b7: "5. Nuts and seeds - contain healthy fats that can be anti-inflammatory.",
b8: "6. Dandelion roots and leaves - this is a bitter herb than can be eaten or made into a tea and helps with overall better digestion.",
b9: "7. Burdock root - a Liver cleanser that can help to clear mucus from your whole system. Clearing the channels around the liver is important because there isn't a direct one leading out of the body.",
b10: "8. Ginger root - decreases Cholesterol and the amount of fat in the Liver. Consume this in a tea or add it to your food.",
source: "https://www.youtube.com/watch?v=OwMbQXfgXt0",
date_created: "2023-07-12"
},
{
id: "how_to_improve_your_gut_health",
category: "health",
title: "How to improve your gut health",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. First thing to do is to switch your mindset towards eating to live and cleansing the body. This can take the form of eating foods that are full of energy and are alive! By switching to a plant-based diet you are no longer putting massive amounts of pressure on your gut to process heavy food materials that have lots of waste and not much nutrients to aid in digestion. It would be better to go for raw plant-based foods, but this can be incorporated over time as most people may not be able to process this straight away.",
b2: "2. Using intermittent fasting so that you give yourself an eating window in which to consume all of your food. For example eating from 12 noon to 7 in the evening. This gives your gut a chance to rest between from 7 to 12 noon the next day. Between these times you can push through by only drinking fluids.. teas, water etc. This gives your body a chance to repair using the food you've consumed, rather than digesting all the time. Choose a window that suits your lifestyle.",
b3: "3. Increase your fibre intake through consuming more vegetables, this will give your gut a variety of fibre types, soluble an insoluble diversity and keep food moving through your system.",
b4: "4. Try to detox your gut, many food types are toxic for the gut and this can remain in the gut for a long time. They will not leave until you treat this through using different herbs like Basil, Oregano Turmeric and Cinnamon. You can do this by cooking with these herbs or making a juice.",
b5: "5. Make sure you are getting adequate amounts of sleep. By getting the right amount of sleep and combining this with your eating windows, this will give the gut a long enough period of time to repair and restore itself and it's function.",
b6: "6. Manage the amount of stress that you are putting on yourself under. The gut may respond badly when your body is stressed and may cause you to put on weight.",
b7: "7. Incorporate the following foods in your diet... 1. Mushrooms - contain pre-biotics that feed the pro-biotics in your gut and will help maintain a healthy weight. 2. Sea Moss gel - is high in fibre. 3. Coconut Kefir - a pro-biotic that is anti-fungal (any unhealthy bacteria that has developed in the gut can be tackled using this), 4. Onions - a pro-biotic that will build up the good bacteria in the gut and keep things balanced as you consume different food types.",
source: "https://www.youtube.com/watch?v=PnHz59f_6e0",
date_created: "2023-07-11"
},
{
id: "argan_oil_for_healthy_skin_hair_nails",
category: "health",
title: "Using Argan oil for healthy skin, hair and nails",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Most of us look for ways to achieve healthy skin, hair and nails. Argan oil is used as a carrier oil similar to Olive oil where you mix the oil together with other oils like Peppermint oil for example and then apply it to the desired area of the body. It can also be used by itself though and is absorbed by the skin easily making it a great option for moisturising day to day.",
b2: "2. Argan oil is obtained through the Argan fruit itself. We remove the skin and interior pulp and then the kernels are crushed and pressed to get the natural unprocessed, organic oil - this is the best kind and is recommended for use.",
b3: "3. The oil contains vitamin E, carotene and fatty acids that can help to strengthen the barrier of skin so that it is better protected from weather conditions and any pollutants you come into contact with.",
b4: "4. It can help to nourish the skin which can be a benefit as we age and begin to loose natural collagen. This basically means it could have an anti-aging effect on the skin, potentially reducing wrinkles.",
b5: "5. It can help with dark marks on the skin's surface and help combat itchy and dry scalp conditions when used over time. Due to the anti-inflammatory nature of this oil, it is unlikely to clog your pores or damage your hair follicles. For most hair types it is easily absorbed by hair strands leaving them shiny, healthier and repaired.",
b6: "6. You can use it in a variety of different areas on the body... on your lips, in your hair, on your face, under your eyes and on your hands and nails.",
b7: "7. It's best to use the 100% natural organic version that is unprocessed, this will ensure you are getting the best quality vitamins that the Argan fruit has to offer.",
source: "https://www.youtube.com/watch?v=pB6cT_qtxkQ",
date_created: "2023-07-10"
},
{
id: "best_types_of_water_to_drink",
category: "food",
title: "Best types of water to drink",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. There are several different water types to choose from when you go shopping, from volcanic, distilled and artesian to purified and ionized. But what is the best to buy and consume? It is important to look at water as a function in our bodies and how we can benefit from consuming fruits and vegetables with high water content.",
b2: "2. We have to be conscious that most of the water we consume comes from tap water in the cities we live in. Most cities produce water that can contain fluoride (a toxin), lead, traces of pharmaceutical drugs and anti-depressants (as people flush products into the water system), arsenic, certain bacteria and viruses, which can be damaging to the body over time.",
b3: "3. It is important to put filter systems in place to cleanse tap water before using it. Remember that boiling should kill all live bacteria, but this can still be present in the water and the body will still need to deal with it.",
b4: "4. Structured water has a chemical formula of h3o2 and is more dense than normal water. It can increase energy, decrease inflammation and slow down aging. This type of water comes from certain foods and can stay in the body for longer periods of time.",
b5: "5. By juicing your fruits and vegetables, you can essentially eat structured water. Some of these foods include pears, apples and cucumbers for example. Once the liquids have been extracted from the fruits and veggies it becomes a water structure full of vitamins and minerals.",
b6: "6. Green leafy vegetables are great for juicing. The chlorophyll that is created from juicing has a very similar molecular structure to our blood - the main difference being that we have iron at the centre and the leaves have magnesium.",
b7: "7. Good food examples are Cucumbers, Courgettes, Lettuce, Watermelon, Cantaloupe, bell peppers, Coconut water - they all have above 90% water content and keep the body hydrated.",
b8: "8. The best water to buy is said to be natural spring water that has been bottled at source and hasn't been processed. This water has usually evaporated up, been rained back down and been collected from the mountains. It therefore should have all the natural minerals from the rocks it would have passed over whilst in the same location.",
b9: "9. Calcium, Magnesium and Potassium are just some of the minerals/chemicals that the water will collect whilst being bottle at source in mountainous areas. Unlike most Alkaline water you can buy which are alkalized synthetically, these chemicals will alkalize the water naturally and add natural electrolytes.",
b10: "10. Overall it's best that the water is bottled at source and isn't transported before being purchased. The environment of the water can affect it's quality dramatically.",
source: "https://www.youtube.com/watch?v=ZL8oWP7ivUQ",
date_created: "2023-07-09"
},
{
id: "benefits_of_cold_water_immersion",
category: "health",
title: "Benefits of cold water immersion",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. In most cases we don't like to be uncomfortable when it comes cold temperatures. Our ancestors have had to adapt to the cold weather conditions of winter, but us, not so much. Cold water immersion / cold water therapy can affect your Genes and health in a positive way.",
b2: "2. Some people have cold water immersion tanks but the same effects can be achieved through cold showers and pools. You'll get more benefits from a cold water shower in comparison to a warm one simply because it's more uncomfortable and causes the body to defend itself and maintain it's own body temperature whilst under the pressure.",
b3: "3. As you begin the immersion, you'll need to focus on your breathing to deal with the temperature of the cold water. Your pulse rate will increase and you will gasp for air. Try to control this with deep breathing techniques, inhaling and exhaling slowly.",
b4: "4. This therapy can help to improve your cardiovascular function and cause decreases in oxidative stress and Cortisol. Though initially your Cortisol may rise, as soon as you are finished this can decrease. The anti-bodies you have can increase and you could decrease your depression as this is process acts as a natural anti-depressant.",
b5: "5. If you are a gym go-er... pain, inflammation, muscle soreness and overall recovery time can all be decreased with this immersion. Doing this for a maximum of around 10 minutes per week should be optimal to experience the benefits.. anything more and this could have negative effects - if for example you are trying to increase your muscle mass.",
b6: "6. Cold water therapy is said to fight against the growth of tumors. The cold water exposure can activate/spike brown fat, which can suck up a lot of glucose that can in turn be the fuel for certain cancer cells in the body.",
b7: "7. Cold water immersion can increase your defense against viruses and enable attacks on the cancers of the body.",
b8: "8. Your mental resilience can increase, you may have more mental toughness and control of your body when in high pressured situations.",
b9: "9. It can help you to generate more blood vessels and cells, helping create more oxygen in the body and enable more efficient use of this oxygen. It can improve your metabolism, help your liver to break down poisons and help anti-oxidants move around your body. You may experience improvement to your DNA and reproduction of related cells.",
b10: "10. If you feel like you could benefit from this therapy but feel your body may not be in the best shape for it right now, B vitamins can help to increase the tolerance of exposure to extreme temperatures. Consult your doctor before using any vitamins.",
source: "https://www.youtube.com/watch?v=GhGSLffZd0E",
date_created: "2023-07-08"
},        
{
id: "healthy_foods_list",
category: "food",
title: "Healthy foods list",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Lettuce - contains Calcium, Magnesium, Potassium, Zinc, Manganese, Selenium, Vitamins B(1, 2, 3, 6), C and K. This is a sample of nutrients available but there are more to benefit from. Arugula, green leaf salads, Kale, and Swiss chard are great to consume as well. Including a range of these foods at some point or other would be perfect. Take care with some foods like Spinach which can contain Oxalates, some people are sensitive to them and they can prevent absorption.",
b2: "2. Extra virgin olive oil - when comparing the natural version of this oil to certain processed oils, it can help with inflammation, cardio vascular system function, blood clotting and blood pressure. You can use this oil to cook with and dress your salads - overall a great whole food.",
b3: "3. Wild caught Salmon - contains Vitamins D, B12, Niacin and Omega 3 (great food for brain function). Salmon also has great protein and a low carbohydrate profile. Other good fatty fish include Mackerel and Sardines.",
b4: "4. Macadamia nuts - great if blended into a smoothy, they have a low carbohydrate profile and are high in protein and have good fats. They also give you a range of vitamins and minerals, other healthy nuts are Pecans and Walnuts. Be aware of the Omega 6 within nuts that can be pro-inflammatory, eating a wide range of different nuts means you can get the benefits of them all.",
b5: "5. Cauliflower - contains lots of vitamins and minerals, is low in carbs and high in vitamin C. It's a widely available food that can be used in a variety of different ways, such as Cauliflower rice. Take care to not fall into trends when it comes to certain healthy foods like these, sometimes they can be masked and have the same nutrient profiles of unhealthy foods. Other similar healthy foods include Broccoli, Asparagus, Brussels Sprouts, Bell pepper and egg plant. They are all non-starchy vegetables with lots of benefits.",
b6: "6. Sauerkraut - fermented Cabbage that is high in vitamin C. Normal Cabbage varieties are great to consume as well.",
b7: "7. Cod liver - you can buy this in a can with the natural oil it comes in and is great to have with a salad. This oil gives you large amounts of Vitamin A, D and Omega 3, so take care on how much you consume in short periods of time.",
b8: "8. Eggs - having just one egg can provide you with most of the nutrients that you'll need in a day. This is a very complete food and is great as a base food product. The quality of the eggs are very important so buying pasture-raised types are the best and mean that the chickens are going outside to eat directly from the environment.",
b9: "9. Avocado - rich, creamy and satisfying. It's high fat, low in carbs, with a great amount of fibre.",
b10: "The foods listed above are just some of the healthy foods and should be consumed with lots of other food types for a balanced diet.",
source: "https://www.youtube.com/watch?v=yIQe8meoqew&t=835s",
date_created: "2023-07-07"
},
{
id: "benefits_of_using_moringa",
category: "food",
title: "Benefits of using Moringa",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Moringa leaf is a super-food that has powerful anti-oxidant and anti-inflammatory properties and can be great for healing the body.",
b2: "2. Packed with vitamins A, C, E and Potassium, this leaf can help fight off the free-radicals that damage the energy function in our cells.",
b3: "3. Through reducing high cholesterol levels, it can help to reduce problems in the arteries and promote the prevention of plaquing.",
b4: "4. It can support brain health and your cognitive function. The high content of vitamins C and E support defense against the degeneration of brain neurons.",
b5: "5. Moringa and many plants contain Polyphenols which can help to protect against liver oxidation and damage.",
b6: "6. It has powerful anti-microbial and anti-bacterial effects on the body that can help to build our immune system.",
b7: "7. By only having a teaspoon of Moringa per day in your food, or having it as a tea, you can protect and bulletproof your body for the better.",
b8: "8. Exposure to chemicals like Arsenic can find it's way into our food and water through mining and agriculture. This exposure over long periods of time can eventually lead to health issues like heart disease. Moringa leaves may help to protect against some of the toxicity of this Arsenic.",
b9: "9. Overall this Indian tree can be great for those that are lacking in essential nutrients, consuming as a tea may be the most convenient and accessible way of consumption.",
source: "https://www.youtube.com/watch?v=YiVo4oc9EZU, healthline",
date_created: "2023-07-06"
},
{
id: "signs_that_your_cortisol_is_too_high",
category: "health",
title: "Signs that your Cortisol is too high!",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. If we have too much Cortisol in the body, the following processes end up in decline: digestion, immunity and our reproductive and healing capabilities.",
b2: "2. If you are gaining weight around the abdominal area, this can mean you are increasing fat around your vital organs.",
b3: "3. One of Cortisol's purposes is to raise blood sugar, when this happens it can mean that your body more often than not needs emergency fuel because it is under pressure. This would mean that your sugar requirement is above that needed at your base line level. Most stress nowadays is mental, this increases glucose usage, then insulin goes up but the body hasn't burnt anything in the way of exercise or physicality, this can have huge negative effects overall.",
b4: "4. Having an increased appetite or craving for foods. Through your body requiring high amounts of sugar and salt, you can identify that your body's minerals are being used up faster. It's important not indulge in snacking to get a quick fix for your energy needs, usually it can just be that you are de-hydrated and need to drink more water or to take a break from whatever you are doing.",
b5: "5. If you frequently catch colds and/or flu's, this could be a sign that you are using large amounts of your resources elsewhere, meaning that your immunity is being affected. Essentially, your body may be doing more to survive in the moment rather than in the long term.",
b6: "6. Cortisol directly affect the cells in the body that build and break down bone in a negative way. It can also decrease calcium absorption so the body can't use it as effectively, causing more calcium to be lost through the urine.",
b7: "7. Muscle weakness can be a major sign of Cortisol being increased. This can cause you to loose muscle in your larger muscle groups and put that weight on around the waist area.",
b8: "8. Cortisol causes a breakdown in Protein, so if you find yourself bruising very easily, your body tissue may be more fragile.",
b9: "9. If you find that you heal quite slowly, high Cortisol levels can inhibit inflammation, one of the first stages of the healing process. This in turn leads to bad healing of wounds like cuts and scratches.",
b10: "10. If you find yourself having poor sleep throughout the night, it may be that the Cortisol level in your body is causing your body to wake up faster than it should. You can help all these symptoms through meditation, exercise and stabilizing of your blood sugar levels.",
source: "https://www.youtube.com/watch?v=2VAxkXP_9c0",
date_created: "2023-07-05"
},
{
id: "Handling_stress",
category: "health",
title: "Handling stress",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Sometimes it can be difficult to remain calm in stressful situations. One of the best ways to manage your stress levels can be your first response to the situation when it actually occurs and presents itself. It might not be possible to completely avoid people or circumstances that can make you slightly hot under the collar or angry but overall you can control the way that you react and the things you say.",
b2: "2. We all use different mechanisms to deal with stress after it has already affected us, but it's important to combat it with healthy ones. Through doing this we can make sure we are not damaging our bodies and causing even more stress for ourselves through the harmful of effects of drugs and others crutches we may turn to.",
b3: "3. Stress can lead to huge problems, creating conditions like diabetes through over-eating. When stressed the body can release cortisol and adrenaline hormones and cause it to switch to glucose for it's energy. You may then be craving foods containing high amounts of sugar.",
b4: "4. Stress weakens the immune system and it puts your body in a less defensive state where viruses can become active. Insomnia, infertility, diabetes, strokes and lack of oxygen in the body are just a few conditions that can be caused.",
b5: "5. Your ability to solve problems are enhanced when you are in a calm state, as opposed to a stressed one where you can't clearly picture problems and find solutions pragmatically. To directly combat this, you can go for walks, write down the things that are making you stressed and also play sports.",
b6: "6. It is important to scan your body and identify the areas where you might be holding on to tension and just let these areas loose. That way you are not focusing so much energy in these particular areas. Look in the mirror, relax your face muscles... relax your stomach muscles when you eat by slowing down when consuming food. Actively try to relax the muscles in your body before you go to sleep for example.",
b7: "7. Try to have the viewpoint of being calm in stressful environments, have a care-free attitude knowing that things will always work themselves out in your favour eventually. Being erratic can only make things worse, take deep breaths before speaking for example, give yourself time to compose your thoughts.",
b8: "8. Try to remove yourself from the past and move into the present so that you're not fixated on things that you cannot change. Remaining in a state where you're stressed thinking about past events won't change anything, you won't improve like that. Go for a walk in nature, the park (it's free), move into the present.",
b9: "9. Doing something physical is also a great way to combat stress. By getting active you can shift your focus to the activity that you are taking part in. Whatever was making you stressed can be physically removed because the activity itself requires you to concentrate on what you are doing - or winning a game you may be competing in.",
b10: "10. It can be good to give your muscles a good stretch to move the blood around your body. Other things that can help are bonding with a pet or making donations to a good cause. By helping others you can improve your mood making you less stressed.",
source: "https://www.youtube.com/watch?v=FF4jZv8iJDI",
date_created: "2023-07-03"
},
{
id: "best_foods_for_your_eyes",
category: "food",
title: "Best foods for your eyes",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. The eyes are made up of the retina and the macular. The retina is like film in your camera and it sends information to the brain through the optic nerve.",
b2: "2. The rods and cones that make up your eyes help you to see in various light conditions and at different angles.",
b3: "3. The most important nutrients for the retina are omega 3 fatty acids, which contain DHA. This is an anti-inflammatory that allows you to regenerate pigment in your eyes so that the retina can responds to light. UV and blue light can be responsible for destroying the DHA that your retina needs to function.",
b4: "4. The macular part of the eye is helped by the carotenoids in food.",
b5: "5. Tuna, beef, egg yolks, cod liver oil and salmon can contain a lot of the DHA you need for your sight.",
b6: "6. Walnuts, Chia seeds and Flaxseeds can also be beneficial for vision.",
b7: "7. Carotenoids can be found in dark leafy greens (kale and salads), carrots and egg yolks. ",
b8: "8. It's important to get the organic and grass fed varieties of food to ensure that the nutrient levels are at there highest and most concentrated.",
b9: "9. It would be beneficial to avoid the omega 6 fatty acids in foods and high levels of sugar. By consuming a high amount of sugar it can lead to free radical damage and could in turn mean that you need to replenish your body with omega 3 and carotenoids.",
source: "https://www.youtube.com/watch?v=wafi94q8gPg",
date_created: "2023-07-02"
},
{
id: "benefits_of_walking",
category: "health",
title: "Benefits of walking",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Most of us think of walking as a very normal action, we do it without really much thought most of the time, almost autonomously. From an exercise point of view we could feel like it's pretty much a waste of time and that we should probably focus on more vigorous forms of exercise to loose weight. This is not always the case as walking is a low impact form of exercise and can seriously reduce your cortisol/stress levels compared to other forms.",
b2: "2. After just 20 minutes, a simple walk can make you feel calmer and less stressed all throughout your body. By performing low intensity long walks you can hold on to your adrenaline for longer without burning all of your energy, making you feel activated and ready for your next task.",
b3: "3. Your creative energy could therefore increase because you're not under the stress caused by working out at a high intensity.",
b4: "4. You may be better at problem solving, you'll have the chance to think through solutions carefully and decide which one to implement without being in the recovery mode of heavy breathing, which happens for example when you are sprinting or burning a lot of energy quickly.",
b5: "5. Walking can help your mood and overall you may have less anxiety and feel less depressed.",
b6: "6. It can help the cells in your body generate more energy, this in turn can reduce the number of diseases you face.",
b7: "7. Walking increases your exposure to the environment outside, meaning that you could have more diverse forms of microbes in your body, increasing your immunity.",
b8: "8. During a walk you give your body the chance to create new ways of anti-oxidising internally. These are methods that don't require the processing of food.",
b9: "9. Walking can increase the cells in your body that fight off certain types of cancer. Bone density and inflammation (reduced stiffness) both see improvements when going for a stroll. Your eyes can also improve as you get more blood to the retina.",
b10: "10. If you have any problems with blood sugars, walking can help as your burn the initial sugar store before burning fat. Carbon dioxide is needed to get oxygen into the blood, by walking, the steady pace means that you can get a nice balance of both oxygen and CO2.",
source: "https://www.youtube.com/watch?v=3Hobt4Pb4iA",
date_created: "2023-07-01"
},
{
id: "antioxidant_to_reduce_visceral_fat",
category: "health",
title: "Antioxidant to reduce visceral fat in the body",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Heart disease and cardiovascular disease lead in causing health problems in our population. As we age we store fat more easily, the kind that surrounds the organs and this is dangerous.",
b2: "2. This can cause a lot of problems for the liver which helps in many processes from digestion and protein synthesis to detoxification. Most diseases that we hear of are tied to inflammation.",
b3: "3. We can combat this through drinking green tea, which contains Catechins that will decrease the body weight, fat mass, visceral fat and bad cholesterol. Matcha green tea can be a good option when choosing a green tea to consume.",
b4: "4. Intermittent fasting and exercise, along side getting a good night sleep can be powerful remedies for this also. A good night sleep will allow your body to secrete human growth hormones which can burn fat and retain muscle.",
b5: "5. Increasing your fiber will also promote the fat burning effects.",
b6: "6. It's important to remember that visceral fat may not be visible by looking at your body. Someone who is 'in shape' maybe still be carrying visceral fat around the organs.",
b7: "7. If you have diabetes or high blood pressure, the chances of you having visceral fat may be higher, so best to adjust the diet and fight against this through food consumption as early as possible.",
b8: "8. Try to not eat too late at night ( I always like to snack before bed which is terrible). By eating in designated windows, you give your body and it's organs a chance to heal and repair fully. You may find that through doing this you have more energy in the morning.",
source: "https://www.youtube.com/watch?v=FTNU5P09oVU",
date_created: "2023-06-30"
},
{
id: "ways_to_stop_overeating_and_be_satisfied",
category: "food",
title: "Ways to stop overeating and be satisfied",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. What are the ways we can feel properly satisfied after eating you ask? Is it by eating more fat or nutrient dense foods?",
b2: "2. If you continue to eat until your protein requirement is met, then you should start to feel full and satisfied. It tends not to be the protein  in the meal itself but the amino acids within the protein that gives the satiety.",
b3: "3. Protein replenish your muscles, tendons, ligaments, collagen, skin, bones and enzymes. All of the bio-chemistry that happens during digestion is made up of protein, which essentially means your body is being fully restored during this time.",
b4: "4. Cells, blood and hormones are all made from protein, so it's important for it to be consumed regularly as the body doesn't store it.",
b5: "5. When your body isn't getting it from your food intake, a mechanism called autophagy uses the damaged proteins in your body to create the amino acids it needs.",
b6: "6. Consuming the correct amount of this compound is crucial, too much and it gets converted to sugar.",
b7: "7. If you multiply your weight in kilograms by 1.6, this can help to estimate roughly how much in grams you need per day.",
b8: "8. By achieving your daily requirement, you greatly increase your chances of feeling full after each meal and in turn ensure that aren't overeating.",
source: "https://www.youtube.com/watch?v=54PDV3ckFXE",
date_created: "2023-06-29"
},
{
id: "vitamind_immune_boosting_foods",
category: "food",
title: "Vitamin D - Immune Boosting Foods",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Rather than a vitamin, Vitamin D is actually a hormone that the body creates itself. The normal way for us to produce vitamin D is through sun exposure but we can get it through the diet in the following forms: vitamin D2 and D3. Vitamin D2 is the form that plants make and Vitamin D3 is the form that humans and animals make. The sun hits our skin, it's processed by the liver and the kidney handles the rest.",
b2: "2. Vitamin D is fat soluble so therefore can be stored so we don't need to produce it everyday. It helps us absorb calcium from food and move it to the blood and bones. It also activates our immune system for our defenses. Low levels can lead to cancer, heart disease, fatigue, obesity and depression.",
b3: "3. It may be beneficial to increase your sun exposure if you have a darker skin complexion as the skin pigment can interfere with the production of vitamin D in the body.",
b4: "4. Many food products are fortified with vitamin D, but this could be with the vitamin D2 form and the body is not the best at using this. This could lead to people still being deficient in the vitamin overall.",
b5: "5. Here's a list of foods that you can eat to get the best vitamin D amounts: 1. Butter, 2. Beef, 3. Eggs, 4. Mushrooms, 5. Cod, 6. Cod Liver oil. 7. Salmon.",
b6: "6. It might be best to get exposure to the sun on as many areas of the body as possible - this can be hindered by clothing.",
b7: "7. The angle of the sun can also be important, the further you are away from the equator could mean you are getting a reduced chance of vitamin D production. Sun creams can also be an issue here.",
b8: "8. Tops tips for getting vitamin D are, getting moderate sun exposure, eating organic foods suggested in step 5 above and maybe using cod liver oil if you have issues with sun exposure or diet.",
source: "https://www.youtube.com/watch?v=uyUoK71uATU",
date_created: "2023-06-28"
},
{
id: "benefits_of_cooking_with_garlic",
category: "food",
title: "Benefits of cooking with Garlic!",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Garlic is a straight up power house and super-food in it's own right. It has anti-microbial properties that can increase your endurance if you are an active person.  It is anti-viral, anti-bacterial, anti-fungal and generally is an anti-biotic without all the side-effects.",
b2: "2. It ensures that unfriendly pathogens do not take over the body and is an absolute must to consume on a daily-basis. Use it to season your food to give it that great flavour. I usually use it alongside red onions when making scrambled eggs.",
b3: "3. It can be beneficial for your respiratory system, decreasing the length of coughs and lung infections you may have.",
b4: "4. It can be very anti-cancer due to the compound it contains called 'Allicin'.",
b5: "5. It can help with Arthritis as it can improve inflammation.",
b6: "6. It can prevent against strokes, high blood pressure and high cholesterol. Improving your overall cardiovascular system.",
b7: "7. It can help food digestion, allowing food to move more easily through the body, removing bloating and gas.",
b8: "8. It can help to detoxify poisonous chemicals in the body so that they are more water soluble.",
b9: "9. It can help with bone density and strength.",
source: "https://www.youtube.com/watch?v=G5gi7nqy2j0",
date_created: "2023-06-27"
},
{
id: "drink_that_improves_your_overall_health",
category: "health",
title: "Have this drink to improve your overall health",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. This drink can help to do the following: Improve your heart, arteries, cardiovascular system, intestines, brain, hormones and insulin levels. Usually the best way to do this is to keep your system clean.",
b2: "Ingredient: Chia seeds - can help to decrease the chance of heart diseases, prevent against certain cancers and improve digestion.",
b3: "Ingredient: Cinnamon - rich in antioxidants, this can help to support your blood sugar control and reduce inflammation. It can also defend against the free radicals in the body and boost your metabolism.",
b4: "Ingredient: Lemon - can help to flush toxins and pathogens out of the body (as they are unable to survive in an alkaline environment), increase your immunity, aid weight loss and prevent kidney stones. It can also improve skin health and boost energy and mood.",
b5: "2. Process: Prepare your blender cup, then take one cup of water and pour it in. Drop in 1 tablespoon of Chia seeds, 1 half of a Lemon squeezed and 1 half teaspoon of Cinnamon.",
b6: "3. Blend this for around 10 seconds, allowing all ingredients to be crushed and mixed.",
b7: "4. Before drinking you can sweeten with a natural sweetener.",
b8: "5. Have this drink 10 - 15 minutes before your first meal of the day.",
b9: "Once digested, the Chia seeds will expand giving you satiety and energy.",
b10: "Start off drinking this once a day - this can also be used before having dinner. Overall this drink can aid your weight loss and improve your health after a few weeks.",
source: "https://www.youtube.com/watch?v=HI-6HoSurGk",
date_created: "2023-06-25"
},
{
id: "signs_that_your_body_needs_more_nutrients",
category: "health",
title: "Signs that your body needs more nutrients",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Excessive yawning could mean that you are iron deficient and you may not be getting enough from your diet.",
b2: "2. Having difficulty breathing sometimes can mean that your body is trying to get more oxygen and could indicate that your blood is too acidic. This can occur because you have a B1 deficiency. Try to reduce the amount of unhealthy carbs you are consuming.",
b3: "3. An eye twitch can be the exact opposite meaning your blood is too alkaline. This state can prevent calcium being transported, affecting your nerves and muscles. Try taking apple cider vinegar to aid calcium transportation around the body.",
b4: "4. If you have aching in your lower back or have hip pains, this could be a vitamin D deficiency. You could try supplements for this or consuming the appropriate foods containing this vitamin.",
b5: "5. If you can't tolerate the cold and/or heat, this could be a B1 and/or iodine deficiency. You could try supplements for these issues.",
b6: "6. If you have a white tongue. This could be an overgrowth of candida and can be caused by a deficiency in probiotics.  You could try supplements for this. This can also be helped if you reduce your sugar intake.",
b7: "7. If you have bloating, stomach pains or indigestion, this can be caused by a lack of acid in the stomach. Try looking into betaine hydrochloride.",
b8: "8. If you are having issues with muscle weakness, this can be caused by low sodium in your diet.",
b9: "9. High blood pressure or pulse rate could mean that you have a potassium or vitamin D deficiency. You could look into supplements for this or consuming the appropriate foods.",
source: "https://www.youtube.com/watch?v=7D35xgIVtQM",
date_created: "2023-06-24"
},
{
id: "acceptable_drinks_when_fasting",
category: "food",
title: "Acceptable drinks when fasting",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. A true fast is when you only drink water throughout your fast, but this might not be the best idea given that a lot of people haven't got as many stored nutrients from food as we they did years ago.",
b2: "2. Water is great to drink when on a fast - best to stay away from tap water as it can contain plastics, chemicals (used to kill off germs) and hormones, but if you do try to use a filter that pulls out fluoride. Spring and/or carbonated water would be best and try to aim for around 2.5 litres per day, depending on your level of activity. You may want to add apple cider vinegar or lemon juice to your water as this will help your blood sugars, digestion and energy.",
b3: "3. Diet sodas are not a good idea when fasting, they can cause you to put on weight. Using Stevia, Monkfruit or Xylitol would be good for sweetening drinks if you need to.",
b4: "4. Black coffee is acceptable to consume during a fast. Best not to have too much caffeine though as this could interfere with your sleep cycle, raise cortisol and slow down weight loss. It might be a good idea to choose organic coffee as coffee beans can be sprayed with pesticides.",
b5: "5. Coffee with half milk and half cream or just whipped cream is ok to drink but best to go with having whole cream.",
b6: "6. Drinking bone broth during a fast wouldn't be a good idea as it has protein and this will push your body out of fasting and affect your results.",
b7: "7. Green tea is great for a fast. The antioxidants in this kind of herbal tea will help your metabolism, but ensure you watch your caffeine intake.",
b8: "8. Coconut water has quite a lot of sugar so should definitely be avoided during a fast. Some Coconut milks can be ok to consume provided they have low carbohydrate amounts.",
b9: "9. Pre-workout powders should be avoided because most contain protein and will affect your fast. Consuming alcohol can toxify your liver so will also be a bad idea.",
b10: "10. Milk contains a large amount of carbs, so should also be avoided. Electrolytes are fine, so long as they don't contain sugar. It's important to consume Electrolytes as you could feel dizzy or faint.",
source: "https://www.youtube.com/watch?v=v0lrJXU92-Q",
date_created: "2023-06-23"
},
{
id: "intermittent_fasting_basics",
category: "food",
title: "Intermittent fasting basics",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. Fasting throughout the day in intervals is one of the most important things you can do to benefit your overall health, in comparison to snacking which is one of the worst.",
b2: "2. During fasting we are trying to go from sugar burning to fat burning. The key indicator of this is that your appetite for eating regularly is going away. You should aim to get to a point where you are controlling your food intake, rather than the food controlling you.",
b3: "3. Start by pushing your breakfast to a later time in the day, maybe after lunch and up until that time, drink only fluids, maybe a coffee. The rule of thumb would be to only eat when you are actually hungry.",
b4: "4. Try to keep your carbs low for your lunch and dinner meals, this way you won't be hungry in the mornings and will make it past lunch time for your first meal. Cortisol can spike in the morning, causing you to feel hungry when you wake up, but this is a feeling you can ignore with self control.",
b5: "5. You will need to include healthy fats in your diet when you eat, the fat will keep you satisfied for longer and allow you to only eat at certain times in the day. This fat can be pecans, olives, butter, nut butters, cheese or fatty meat. This is needed so that you can survive as your body adapts to the new source of energy.",
b6: "6. Make sure that you are consuming healthy ingredients that are organic if possible. The first meal should be around 12 and the next around 6, which would give you a six hour eating window. As you begin you can include some healthy snacks like nuts but try to move towards not having these as you get better. This gives you 18 hours of fasting as you sleep. You'll grow brains, have better creativity and your mood will be better. Your immune system will improve and you'll generate new stem cells.",
b7: "7. Try to stop snacking at night time before you go to bed as this will disrupt your fast. Try to stay disciplined on this and keep yourself busy. This can be helped by having a large dinner at 6. At the start you may still be insulin resistance which will mean your body may find it difficult to absorb nutrients from your dinner meal to keep you satisfied - try having a glass of water with a tablespoon of apple cider vinegar or lemon juice so you can absorb as many nutrients as possible.",
b8: "8. Vegetables and salads will help to increase the nutrients that are available for absorption. This will prevent you getting tired as you'll have more energy during your fast.",
source: "https://www.youtube.com/watch?v=3dHcT1-K-tw",
date_created: "2023-06-22"
},
{
id: "reducing_sugar_intake",
category: "health",
title: "Reducing sugar intake",
pointOfInterest: 130,
backgroundColor: "#00",
b1: "1. By reducing sugar in your diet you decrease the craving for sugar itself.",
b2: "2. You'll be less hungry as you stabilise your blood sugars, it's the sugar that keeps you hungry! This means that your cells can actually be fed naturally. Due to sugar being toxic for the body, it automatically rejects it (insulin resistance).",
b3: "3. You can then absorb nutrients in a more complete way. Insulin resistance can block minerals and nutrients from being absorbed by the body.",
b4: "4. Overall you'll have less fatigue and now you are consuming less sugar your brain will be less tired after having a meal.",
b5: "5. You will loose a lot of weight in the form of water and fat in the first few weeks, this is due to the amount of fluid you were retaining. Eating sugar expands your stomach, whereas reducing sugar causes it shrink.",
b6: "6. Your mood will now be vastly improved and you should feel more focused and concentrated on tasks throughout the day.",
b7: "7. You should benefit from less stiffness in the joints. You'll be building new enzymes so that your body can run off a fat fuel source.",
b8: "8. You will start to have decreased inflammation in the arteries which will decrease the chances of you having a stroke or heart attack in the long run.",
b9: "9. You will also start growing brain cells because the body will be running on ketones, which support the growth of nerve cells",
b10: "10. Your liver will now be shredded of the fat it has around it and your Kidney function will also improve.",
source: "https://www.youtube.com/watch?v=mRj1RKh4xyY",
date_created: "2023-06-21"
},
{
id: "the_art_of_list_management",
category: "health",
title: "The art of list management",
pointOfInterest: 130,
backgroundColor: "#00",
text: "Creating detailed lists can be a great way to organise your life and thoughts. Some may feel as though they have no use for them but in my experience, they can be used as full-blown management tools. After buying any small notebook from the shop that can be carried around easily, I start by writing the date and underlining it, then writing a bullet point list of all the tasks I want to achieve in shopping list format. I find that throughout the day I subconsciously carry out these tasks completing them as mini goals. They almost appear to shape the way my day is structured because I already know what I need to achieve and have committed it to paper, so it eventually gets done! I sometimes find myself re-visiting the list multiple times throughout the day or coming back to it the following day or even in the next two days. As I said, the fact that the tasks have been written down, it seems to make it inevitable that it will get completed – whatever it is. When I re-visit the list, I run through all the tasks and tick the ones I've completed or put a note next to tasks to update its status, for example, whether it’s ‘done’ or a short note about any progress made. If a task hasn't been done and it's a new day, I'll re-write the task under the new date and give it more priority in my day to get it done. It may be that a task can't be completed as quickly as I thought and needs more research. I'll then add another bullet point task to do that research so the original task can be moved towards completion. Overall, I find that I have more productive days and I am more accountable for what I add to the list. Currently I keep two separate journals of lists, one for work and another for personal day to day tasks.",
date_created: "2023-04-17"
},
{
id: "apple_cider_vinegar",
category: "food",
title: "Using Apple Cider Vinegar!",
pointOfInterest: 180,
backgroundColor: "#00",
text: "This vinegar can help to move food through your digestive system and act as a catalyst to cleaning up your body. In my experience I usually use it by mixing a teaspoon full in a glass of water and drinking it straight after eating. It seems to break down the foods I have just consumed and removes any bloating that I would normally experience had I not used it. Even though it is important to control your portion sizes when eating, I find that using this remedy can really help to simply act as any other vinegar does, to cleanse and detoxify what it comes in to contact with. I sometimes make use of it through seasoning my salads, using it almost as a dressing. Mixing this together with black pepper seems to add to the potency of the roughage given by the leafy greens. Overall, apple cider vinegar can be used as a way to ensure that the food you are consuming is making it's way through your body in it's entirety and is being used in the most efficient way, this can be great if you are trying to loose weight. Also using this product before a gym workout seems to activate it further. When using vinegar though I would advise using a very small amount and assessing how your stomach reacts to it.",
date_created: "2023-04-17"
},
{
id: "quit_smoking_part_1",
category: "health",
title: "Quit smoking part 1",
pointOfInterest: 120,
backgroundColor: "#00",
text: "When quitting smoking it is important to remember that quitting is a journey and it may take you a few attempts to quit for good! At first I attempted to quit by throwing all of my smoking items in the bin, but felt sad because I felt like I was throwing away my money. I now find it useful to fill my days with activities and to fully commit to not smoking and to actually act as if I am in a physical battle with not doing it. It's not an option to purchase tobacco because I will not use my financial resources, only to destroy my body.",
date_created: "2023-02-05"
},
{
id: "quit_smoking_part_2",
category: "health",
title: "Quit smoking part 2",
pointOfInterest: 250,
backgroundColor: "#00",
text: "When making the decision to stop, make sure you have outlets to occupy your time. I find it useful to use my local gym, use the sauna, go for long walks, consume foods and drinks that aid nicotine removal from the body etc. All of these used in combination make me feel that the nicotine is leaving my body and I am getting closer to the point that I no longer need to top up the nicotine craving. It is said that the addiction comes as the body needs to top up the current amount in your body. So if you are continuously doing activities that are removing the substance, the cravings should get weaker and weaker. Also remember that cravings only last a few mins and then they go away! Sometimes I find it useful to get out for fresh air, I've noticed that warm, stuffy environments cause me to get short of breath and I believe that at this point, most people try to expand their air intake, but usually do this by having a smoke.",
date_created: "2023-02-05"
},
{
id: "quit_smoking_part_3",
category: "health",
title: "Quit smoking part 3",
pointOfInterest: 200,
backgroundColor: "#00",
text: "I find that it's very important to learn to breathe correctly. Most people are shallow breathing, which I find usually means they go outside to get fresh air and ultimately more air into their lungs, the problem here is they go outside (or stay inside) and inhale in the correct way, but unfortunately inhale smoke simultaneously. It might be difficult, but try going outside in the cold air and inhaling only through the nose and out through the mouth. Replicate exactly the breathing pattern you would use if you had a cigarette/rolled tobacco. I believe this added to other strategies will help.",
date_created: "2023-02-18"
},
{
id: "quit_smoking_part_4",
category: "health",
title: "Quit smoking part 4",
pointOfInterest: 200,
backgroundColor: "#00",
text: "Today I'm having a few eye aches and headaches. I am supplementing with Supermalt (less sugar version) to keep my energy regulated and as a small treat. I've noticed that increasing the number of showers I take throughout the day is helping to give me something to look forward to and it appears to be ridding my body of unwanted toxins and generally keeping my mind and body fresh. Yesterday I went to the gym in the middle of the day as I had quite a few uncomfortable pains in the groin areas. So aside from the running I usually do, I simply went into the gym to stretch. I used the leg press machine and literally one leg at a time pushed around 4 - 10kg, ensuring that I targeted all of the appropriate muscles around the groin area. I left the gym feeling fully loose and supple and ready to take on the day! Before leaving the gym I tried my hand at my first dead-lifts using 5kg on each side. It felt decent but not sure if it's worth it yet. I decided to try it as I found out it could help with lower back aches. Seemed to work out ok but I didn't want to over work any muscles first time around. I'll try it again soon.",
date_created: "2023-02-19"
},
{
id: "quit_smoking_part_5",
category: "health",
title: "Quit smoking part 5",
pointOfInterest: 10,
backgroundColor: "#00",
text: "Ensure to keep yourself fully entertained with healthy activities for the mind and soul. I am really into football and the Premier league and also regularly play five a side football. I also now have a Now subscription which allows me to watch games live and keeps me entertained and helps to keep me busy during my down times. It can also help to focus the mind as you plan your days around when live matches are being aired. Liverpool vs Brentford, let's gooo!!! I have established a casual routine of using the gym every other day to run on the treadmill for 30 mins and do some machine work and ab work. In between these days I like to go for walks and ride my bike. These flows help to occupy the mind so I don't get bored, I also find that a few coffees in the morning following by some decafs in the evening are a great way to keep my energy levels up. I also have a lot of DIY projects around my flat that need to be planned and executed. So sourcing materials and planning what I need to buy is a great way to fill the days.",
date_created: "2023-02-25"
},
{
id: "quit_smoking_part_6",
category: "health",
title: "Quit smoking part 6",
pointOfInterest: 130,
backgroundColor: "#00",
text: "Whilst on your journey, fill your days with keeping your environment clean and improving the air flow and quality. Buying plants, especially those with dark green leaves will cause your environment to have fresh and clean air circulating through it. I find this is important because this fairs nice with any breathing exercises you undertake during cravings. Also try not to keep your environment too warm to promote fresh air. On your journey, particularly at the beginning, remember that you may run out of energy and feel tired. I wouldn't personally advise subsidising this with sweet food and drink, as this can have negative effects on your overall weight if done continuously. What I've found great is to close your eyes for around 3 mins to re-charge yourself and if you're at home, don't feel any way about laying down for something resembling a power-nap/break. I find this helps to reset yourself. I feel it's during these moments you would have probably popped out for a smoke!",
date_created: "2023-02-25"
},
{
id: "quit_smoking_part_7",
category: "health",
title: "Quit smoking part 7",
pointOfInterest: 130,
backgroundColor: "#00",
text: "Ensure that you visit your local supermarket and purchase a cordial drink that is low in sugar (or any drink that is low in sugar) so that you can increase hydration in your body. I would advise picking a drink that you find pleasant and you can drink anytime of the day, morning or night. As you go through the day, keep yourself hydrated, this may mean that you are more regular passing urine but I feel that will help to flush the body. I've researched that the nicotine can remain in hair, nails, blood and urine for a long time after you quit. Do your own research on this!",
date_created: "2023-03-05"
},
{
id: "quit_smoking_part_8",
category: "health",
title: "Quit smoking part 8",
pointOfInterest: 130,
backgroundColor: "#00",
text: "It's been a few days since I last had a smoke and my mind feels refreshed and I am able to concentrate on tasks in a more focused way. My eye-sight seems to have improved and my hair feels softer. I've noticed that I need to include slightly more food in each of my meals however, because I am eating healthily, this just means a little bit more of the same types of food. I find myself wanting to plan more activities to keep myself occupied and I tend to go to sleep and wake up earlier, with a passion to start the day. Up until now, I have filled my days with normal day job activities alongside making my environment more cosy and fresh through cleaning. It helps to pick an area of your home, then dedicate half an hour to an hour to bring it up to standard. A big part of my day now is having a fresh bottle of cold water so that I can have a semi-sweet refreshing drink whenever I need to... sometimes I have a drink outside to help with my breathing. I now read a lot more and plan to order a lot more autobiographies. I need to plan more sauna visits in the days to come.",
date_created: "2023-03-23"
}
]
export default data;