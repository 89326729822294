const data2 = [
{
    id: "why_my_food_wont_digest",
    category: "food",
    title: "Why my food won't digest?",
    pointOfInterest: 130,
    backgroundColor: "#00",
    b1: "This is an interesting question that most of us have asked ourselves at least once throughout our lives. You could have just finished a nice meal then been faced with bloating or just an uncomfortably full stomach that makes it difficult to move and be mobile.",
    b2: "This is a topic we should be aware of as the purpose of eating is to nourish our bodies. We want to avoid situations where the food is sitting in the gut undigested, fermenting and affecting our good bacteria. If we can't extract food nutrients, we need to know the potential reasons so we can remedy this going forwards.",
    b3: "1. Low stomach acid - sometimes we eat too many bad carbs and we haven't got enough acid to balance it out. Our bodies produce acid to enable us to breakdown the foods we consume, but if this is low, the food can remain in the stomach for a long time.",
    b4: "2. Improper combination of foods - sometimes our digestive systems are over-working, there can be too many chemical reactions between acid and alkaline in the stomach. Certain combinations of foods can basically cause slower break down. We can remedy this by doing the following, a. eating fruits by themselves on an empty stomach, b. not eating proteins and starches together.",
    b5: "3. Drinking too much with meals - this can cause a dilution of the stomach acid and potentially make it less effective for breaking down food. This includes all types of fluid, including water. It's actually better for us to drink before our meals, so we are hydrated before we start eating.",
    b6: "4. Gut imbalance - unhealthy diets can cause an over-growth of bad bacteria and cause digestive issues. The good bacteria carry out the roles of maintaining weight and making us feel good overall.",
    b7: "5. Poor diet - we need to take care about what foods we put in our trolleys when shopping. Reading the labels to check what's actually in the foods themselves is a must. Processed foods can wreak havoc for our digestion, and also the way that we store foods in our fridges can play a part too.",
    b8: "Foods in jars, plastics and cans, can mean that the natural elements have been pulled out of the food, and replaced with substances that are not natural. The body won't recognise these foods straight away and won't be able to process them efficiently. It's better to eat natural, real food.",
    source: "https://www.youtube.com/watch?v=yYdrSwhPMkw",
    date_created: "2023-07-20"
},        
{
    id: "avoid_these_foods_to_improve_your_skin",
    category: "health",
    title: "Avoid these foods to improve your skin",
    pointOfInterest: 130,
    backgroundColor: "#00",
    b1: "Many people with skin problems may go to get a diagnosis or may even try to cover their skin with makeup or creams to improve the appearance of the skin on the outside. These things can help in the short term and temporarily but it's best to try and correct the underlying issues that are contributing to the actual makeup of your skin cells - through your diet.",
    b2: "1. Low fat foods can be a cause for your skin troubles. Around half of our skin cells are made of saturated fats (which are fine to eat), but we should remember to consume these without the added sugars they are usually bundled with in the market.",
    b3: "2. Sugary foods can ultimately increase the size of the oil glands in your skin and cause acne problems. This can come in the form of fizzy drinks and sweet fruit juices. If you want to consume fruit juices, best to consume the whole fruit itself alongside it's juices.",
    b4: "3. Vegetable oils such as corn, soy, canola and cottonseed can be badly processed and cause inflammation of the skin. They can cause competition for the omega-3 fatty acids in your body which are used for anti-inflammatory purposes.",
    b5: "4. Grains used for alcohol production and other foods can be causing big problems, they can be high in omega-6 fatty acids and also eventually turn into sugar.",
    b6: "5. Soy protein isolates are plant based proteins that can be highly processed. They can end up drying out the skin and lead to bloating.",
    b7: "6. Milk and protein gym powders can be too high in protein and affect the glands of the skin.",
    b8: "7. Junk food - poor quality foods won't contain the antioxidants required to protect your skin from environmental pollutants and even from normal sun exposure!",
    b9: "8. Artificially sweetened foods, for example... certain soups, canned foods and juices can also become an issue for the skin.",
    source: "https://www.youtube.com/watch?v=23i8HNdTRV4",
    date_created: "2023-07-14"
}
]
export default data2;