import React from "react";
import { Header } from "./Header";
import { Welcome } from "./Welcome";
import { CardList2 } from "./CardList2";
import { CardList } from "./CardList";
import { Footer } from "./Footer";
import "./index.css";

const App = () => {
  return (
    <div className="container">
      <Header />
      <Welcome />
      <CardList2 />
      <CardList />
      <Footer />
    </div>
  );
};

export default App;
