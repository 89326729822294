import React from "react";
import { motion, useInvertedScale } from "framer-motion";
import { closeSpring } from "./animations";

export const Image = ({id, category, isSelected, pointOfInterest = 0, backgroundColor}) => {
  const inverted = useInvertedScale();
  // const [image1, setImage1] = useState('');
  // const [image2, setImage2] = useState('');

  // setImage1(`images/food.jpg`);
  // setImage2(`images/apple_cider_vinegar.jpg`);

    // useEffect( () => {

    //   setImage1("`images/food.jpg`");
    //   setImage2("`images/apple_cider_vinegar.jpg`");


  // }, [])

  // useEffect( () => {

  //   if (category = 'Food')
  //   {
  //     //setImage1('food'); images/${image}.jpg
  //     setImage1(`images/food.jpg`)
  //   }
  //   else
  //   {
  //     //setImage2('apple_cider_vinegar');
  //     setImage2(`images/apple_cider_vinegar.jpg`)

  //   }

  // }, [])

  return (
    <motion.div
      className="card-image-container"
      style={{ ...inverted, backgroundColor, originX: 0, originY: 0 }}
    >
      <motion.img
        className="card-image"
        src={`images/${category}.jpg`}
        //src={`images/${image}.jpg`}
        //src={`${image1} || ${image2}`}
        alt=""
        initial={false}
        animate={
        isSelected ? { x: -20, y: -20 } : { x: -pointOfInterest, y: 0 }
        }
        transition={closeSpring}
      />
    </motion.div>
  );
};
