import * as React from "react";
import { memo, useRef, useState } from "react";
import { motion, useMotionValue } from "framer-motion";
import { Link } from "react-router-dom";
import { useInvertedBorderRadius } from "./use-inverted-border-radius";
import { Title } from "./Title";
import { Image } from "./Image";
import { Text } from "./Text";
import { openSpring, closeSpring } from "./animations";
import { useScrollConstraints } from "./use-scroll-constraints";
import { useWheelScroll } from "./use-wheel-scroll";
import { auth } from "./../firebase-config";
import { onAuthStateChanged } from "firebase/auth";

// Distance in pixels a user has to scroll a card down before we recognise
// a swipe-to dismiss action.
const dismissDistance = 150;

// Overlay component displayed when user cicks on card
const Overlay = ({ isSelected }) => (
  <motion.div
    initial={false}
    animate={{ opacity: isSelected ? 1 : 0 }}
    transition={{ duration: 0.2 }}
    style={{ pointerEvents: isSelected ? "auto" : "none" }}
    className="overlay"
  >
    <Link to="/" />
  </motion.div>
);

//Building the card content using higher order component
//component renders the same result given the same props - memoizing the result
//react will skip rendering the component and reuse the last rendered result
//memoizing - speeds up app by returning cached result when the same input occurs

export const Card = memo( 
  ({
    isSelected,
    id,
    title,
    category,
    history,
    //pointOfInterest,
    //backgroundColor,
    text,
    b1,
    b2,
    b3,
    b4,
    b5,
    b6,
    b7,
    b8,
    b9,
    b10,
    date_created
  }) => {
    const y = useMotionValue(0);
    const zIndex = useMotionValue(isSelected ? 2 : 0);
    const [user, setUser] = useState({});
    //const [open, setOpenArticle] = useState("")

    onAuthStateChanged(auth, (currentUser) => {
      console.log('currentUser', currentUser);
      if (currentUser === null) {
        return
      } else {
        setUser(currentUser);
        }
    });

    // Maintain the visual border radius when we perform the layoutTransition by inverting its scaleX/Y
    const inverted = useInvertedBorderRadius(20);

    // We'll use the opened card element to calculate the scroll constraints
    const cardRef = useRef(null);
    const constraints = useScrollConstraints(cardRef, isSelected);

    function checkSwipeToDismiss() {
      y.get() > dismissDistance && history.push("/");
    }

    function checkZIndex(latest) {
      if (isSelected) {
        zIndex.set(2);
      } else if (!isSelected && latest.scaleX < 1.01) {
        zIndex.set(0);
      }
    }

    // When this card is selected, attach a wheel event listener
    const containerRef = useRef(null);
    useWheelScroll(
      containerRef,
      y,
      constraints,
      checkSwipeToDismiss,
      isSelected
    );

    return (
      <li ref={containerRef} className={`card`}>
        <Overlay isSelected={isSelected} />
        <div className={`card-content-container ${isSelected && "open"}`}>
          <motion.div
            ref={cardRef}
            className="card-content"
            style={{ ...inverted, zIndex, y }}
            layoutTransition={isSelected ? openSpring : closeSpring}
            drag={isSelected ? "y" : false}
            dragConstraints={constraints}
            onDrag={checkSwipeToDismiss}
            onUpdate={checkZIndex}
          >
            <Image
              id={id}
              isSelected={isSelected}
              category={category}
              // pointOfInterest={pointOfInterest}
              // backgroundColor={backgroundColor}
            />

            <Title title={title} category={category} isSelected={isSelected} date_created={date_created} />
            { user && user.email ?
            (<Text
             text={text}
             b1={b1}
             b2={b2}
             b3={b3}
             b4={b4}
             b5={b5}
             b6={b6}
             b7={b7}
             b8={b8}
             b9={b9}
             b10={b10} />) :

             (<Text
              text={"Create account or Log in to read this article!"}/>)
             }
          </motion.div>
        </div>
        {!isSelected && <Link to={id} className={`card-open-link`} />}
      </li>
    );
  },
  (prev, next) => prev.isSelected === next.isSelected
);


