import React, { useState, CSSProperties } from "react";
import { motion } from "framer-motion";
import { auth } from "./firebase-config";
import { useFormik } from "formik";
import * as Yup from "yup";
//import emailjs from "@emailjs/browser";
import ClipLoader from "react-spinners/ClipLoader";
import { onAuthStateChanged,
         signInWithEmailAndPassword } from "firebase/auth";

export const Login = () => {

  const [submitted, setSubmitted] = useState(false);
  const [failedLogin, setFailedLogin] = useState(false);
  //const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [user, setUser] = useState({});

  onAuthStateChanged(auth, (currentUser) => {
    console.log('currentUser', currentUser);
    if (currentUser === null) {
      return
    } else {
      setUser(currentUser);
      }
  });

  // const logout = async () => {
  //   await signOut(auth);
  //   return (
  //     <motion.div
  //       className="modal"
  //       initial={{ opacity: 0, y: 50 }}
  //       animate={{ opacity: 1, y: 0 }}
  //       exit={{ opacity: 0, y: 50 }}
  //     >
  //       <p className="modal-intro">You are logged out!</p>

  //     </motion.div>
  //   );
  // };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");

  // const length = 5;

  // makeValue()

  // function makeValue() {
  //   let result = '';
  //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //   const charactersLength = 62;
  //   let counter = 0;
  //   while (counter < length) {
  //     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //     counter += 1;
  //   }

  //   useEffect( () => {

  //     setValue(result);

  //     const c = document.getElementById("myCanvas");
  //     const ctx = c.getContext("2d");
  //     ctx.font = "30px Arial";
  //     ctx.fillText(result,10,50);

  //   }, [])

  //   return result;
    
  // }

  const formik = useFormik({
    
    initialValues: {
      email: '',
      password: '',
      // captcha: '',
    },

    validationSchema: Yup.object().shape({
      email: Yup.string().required("*Your email is required").email("*Please enter a valid email"),
      password: Yup.string().required("*A password is required")
      // captcha: Yup.string()
      //   .required("*Your captcha value is incorrect, please re-try")
      //   .test("captcha_validation", "*Your captcha value is incorrect, please re-try", function (ca) {
      //     if(ca === value){
      //       return true;
      //     } else {
      //       return false;
      //     }
      //   })
    }),

    onSubmit: async () => {
      setDisabled(true);
      setLoading(true);

      try {
        const user = await signInWithEmailAndPassword(auth, formik.values.email, formik.values.password);
        setSubmitted(true);
      } catch (error) {
        setFailedLogin(true);
      }   

      setLoading(false);
      setDisabled(false);

      // if (user && user.email) {
      //   setSubmitted(true);
      //   window.scrollTo({top: 0, left: 0, behavior: 'auto'});
      // }
    }
  }
  );

  if (submitted) {
    return (
      <motion.div
        className="modal"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
      >
        <p className="modal-intro">You are logged in!</p>

      </motion.div>
    );
  }

  if (failedLogin) {
    return (
      <motion.div
        className="modal"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
      >
        <p className="modal-intro">Incorrect email or password, please try again!</p>

      </motion.div>
    );
  }

return (

  <motion.div
    className="modal"
  >
    {/* <h4 className="date">
          <button onClick={logout}> Sign Out </button>
    </h4> */}
    <form
      id="sform"
      onSubmit={formik.handleSubmit}>
      <h4> Log in </h4>

      <label htmlFor="email">Email</label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="Email..."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
          {formik.touched.email && formik.errors.email ? 
          (<div className="modal-errors">{formik.errors.email}</div>) : <br />}

      <label htmlFor="password">Password</label>
        <input
          id="password"
          name="password"
          type="password"
          placeholder="Password..."
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
        />
          {formik.touched.password && formik.errors.password ? 
          (<div className="modal-errors">{formik.errors.password}</div>) : <br />}

      {/* <label htmlFor="captcha">Captcha</label>
        <div>
          <canvas
            id="myCanvas"
            width="200"
            height="100"
            style={{ border: "1px solid #d3d3d3" }}
          >
            Your browser does not support the HTML canvas tag.
          </canvas>
        </div>

        <input
          id="captcha"
          name="captcha"
          type="text"
          placeholder="Enter captcha text above"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.captcha}
        />
          {formik.touched.captcha && formik.errors.captcha ? 
          (<div className="modal-errors">{formik.errors.captcha}</div>) : <br />} */}

      <button
        type="submit"
        disabled={disabled}
      >
        Log in
      </button>
      { loading &&
          <ClipLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={50}
          />
      }
    </form>
  </motion.div>
  );
};