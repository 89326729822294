//Sets up the way framer-motion components animate
export const openSpring = { 
    type: "spring",
    stiffness: 200,
    damping: 30 
};

export const closeSpring = { 
    type: "spring", 
    stiffness: 300, 
    damping: 35 
};
