import React, { useState, useEffect, CSSProperties } from "react";
import { motion } from "framer-motion";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase-config";
import { useFormik } from "formik";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import ClipLoader from "react-spinners/ClipLoader";

export const Modal = () => {

  const storiesCollectionRef = collection(db, "stories");
  const [submitted, setSubmitted] = useState(false);
  const [value, setValue] = useState('');
  const [disabled, setDisabled] = useState(false);

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "blue",
  };

  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#ffffff");
  
  const length = 5;

  makeValue()

  function makeValue() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = 62;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }

    useEffect( () => {

      setValue(result);

      const c = document.getElementById("myCanvas");
      const ctx = c.getContext("2d");
      ctx.font = "30px Arial";
      ctx.fillText(result,10,50);

    }, [])

    return result;
    
  }

  const formik = useFormik({
    
    initialValues: {
      name: '',
      email: '',
      title: '',
      story: '',
      captcha: '',
    },

    validationSchema: Yup.object().shape({
      name: Yup.string().required("*Your name is required"),
      email: Yup.string().required("*Your email is required").email("*Please enter a valid email"),
      title: Yup.string().max(40, "*Your title is too long, please shorten it.").required("*Your article title is required"),
      story: Yup.string().max(2000, "*Your article is too long, please shorten it.").required("*Your article is required"),
      captcha: Yup.string()
        .required("*Your captcha value is incorrect, please re-try")
        .test("captcha_validation", "*Your captcha value is incorrect, please re-try", function (ca) {
          if(ca === value){
            return true;
          } else {
            return false;
          }
        })
    }),

    onSubmit: async () => {
      emailjs
      .sendForm(
        "default_service",
        "welcome_email",
        "#sform",
        "0nSATIvjEEVkAXuG8"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      setDisabled(true);
      setLoading(true);
      await addDoc(storiesCollectionRef, {
        name: formik.values.name,
        email: formik.values.email,
        title: formik.values.title,
        story: formik.values.story
      });
      setLoading(false);
      setDisabled(false);
      setSubmitted(true);
      window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    }
  }
  );

  if (submitted) {
    return (
      <motion.div
        className="modal"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 50 }}
      >
        <p className="modal-intro">Thanks for sharing your article, it will appear on the network within 48 hrs.</p>

      </motion.div>
    );
  };

return (

  <motion.div
    className="modal"
    // initial={{ opacity: 0, y: 50 }}
    // animate={{ opacity: 1, y: 0 }}
    // exit={{ opacity: 0, y: 50 }}
  >
    {/* <p className="modal-title">
      Welcome to Nooching health!
    </p> */}

    <p className="modal-intro">
      Share your health article using the form below.
    </p>

    <p className="modal-intro">
      We'll review it and upload it so that other users can get inspired!
    </p>
    <br /><br />

    <form
      id="sform"
      onSubmit={formik.handleSubmit}>
      
      <label htmlFor="name">Your name</label>
        <input
          id="name"
          name="name"
          type="text"
          placeholder="Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
          {formik.touched.name && formik.errors.name ? 
            (<div className="modal-errors">{formik.errors.name}</div>) : <br />}

      <label htmlFor="email">Your email</label>
        <input
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
          {formik.touched.email && formik.errors.email ? 
          (<div className="modal-errors">{formik.errors.email}</div>) : <br />}

      <label htmlFor="title">Title of your article</label>
        <input
          id="title"
          name="title"
          type="text"
          placeholder="Title"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
        />
          {formik.touched.title && formik.errors.title ? 
          (<div className="modal-errors">{formik.errors.title}</div>) : <br />}

      <label htmlFor="story">Write your article</label>
        <textarea
          id="story"
          name="story"
          type="text"
          placeholder="Article"
          rows="10"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.story}
        >
        </textarea>
          {formik.touched.story && formik.errors.story ? 
          (<div className="modal-errors">{formik.errors.story}</div>) : <br />}

      <label htmlFor="captcha">Captcha</label>
        <div>
          <canvas
            id="myCanvas"
            width="200"
            height="100"
            style={{ border: "1px solid #d3d3d3" }}
          >
            Your browser does not support the HTML canvas tag.
          </canvas>
        </div>

        <input
          id="captcha"
          name="captcha"
          type="text"
          placeholder="Enter captcha text above"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.captcha}
        />
          {formik.touched.captcha && formik.errors.captcha ? 
          (<div className="modal-errors">{formik.errors.captcha}</div>) : <br />}

      <button
        type="submit"
        disabled={disabled}
      >
        Submit
      </button>
      { loading &&
          <ClipLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={50}
          />
      }
    </form>
  </motion.div>
  );
};