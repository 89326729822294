import React from "react";
import { motion } from "framer-motion";
import { useState } from "react";
import { Modal } from "./Modal";

export const Welcome = () => {
  const [isShown, setIsShown] = useState(false);
  const [buttonText, setButtonText] = useState("Add Article");

  const handleClick = event => {
    setIsShown(current => !current);
    setButtonText((buttonState) => (buttonState === "Close" ? "Add Article" : "Close"));
  };

return (
  <p>
  <motion.div
    className="modal"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 100, y: 0 }}
    exit={{ opacity: 0, y: 50 }}
  >
    <p className="modal-title">
      Welcome to Nooching health!
    </p>
    <button
      className="add-article-button"
      onClick={handleClick}
    >
      {buttonText}
    </button>
  </motion.div>

  {isShown && (
    <div>
      
    </div>
  )}

  {isShown && 
    <p>
    <Modal />
    <p></p>
    <p></p>
    <hr className="hrule" />
    </p>
  }
  </p>
  );
};